import React from "react";
import { PageLayout } from "@components";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "./index.module.scss";
import BackgroundFadeContainer from "@components/BackgroundFadeContainer/BackgroundFadeContainer";
import { SectionTitle } from "@components/SectionTitle/SectionTitle";
import { ArrowLinkButton } from "@components/Button_new/Button";

export default function ErrorPage() {
	return (
		<PageLayout
			defaultHeader="light"
			seoOptions={{ type: "PREDEFINED", pageName: "404" }}
		>
			<BackgroundFadeContainer type="solidLightPink" withHeaderPadding>
				<div className={styles.wrapper}>
					<StaticImage
						loading="eager"
						src={"./404Illustration.png"}
						alt="404 illustration"
						style={{ maxWidth: 300 }}
					/>
					<SectionTitle
						title="Sorry! We can’t find the page you’re looking for."
						subtitle="Here are some helpful links for you:"
						textColor="text-dark"
						titleWidth={506}
						subtitleWidth={374}
						marginTop={80}
						marginTopTablet={80}
					/>
					<div className={styles.links}>
						<ArrowLinkButton color="marine" link="LANDING">
							Home page
						</ArrowLinkButton>
						<ArrowLinkButton color="marine" link="PRICING">
							Pricing
						</ArrowLinkButton>
						<ArrowLinkButton color="marine" link="CONTACT_US">
							Contact us
						</ArrowLinkButton>
					</div>
				</div>
			</BackgroundFadeContainer>
		</PageLayout>
	);
}
